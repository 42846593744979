/* :root {
  scroll-snap-type: y mandatory;
  overscroll-behavior-y: none;
} */
/* Regular */
@font-face {
  font-family: "Satoshi";
  src: local("Satoshi Regular"),
    url("../fonts/Satoshi-Regular.otf") format("opentype");
  font-weight: 400;
}

/* Medium */
@font-face {
  font-family: "Satoshi";
  src: local("Satoshi Medium"),
    url("../fonts/Satoshi-Medium.otf") format("opentype");
  font-weight: 500; /* Medium font weight */
}

/* Black */
@font-face {
  font-family: "Satoshi";
  src: local("Satoshi Black"),
    url("../fonts/Satoshi-Black.otf") format("opentype");
  font-weight: 900; /* Black font weight */
}

html {
  height: 100%;
  /* overflow: auto; */
}
body {
  font-family: "Satoshi", sans-serif, -apple-system, BlinkMacSystemFont;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  font-size: 20px;
}

.header-container {
  display: flex;
  align-items: center;
  /* border-bottom: 1px solid #dfdfdf; */
  position: absolute;
  background-color: transparent !important;
  width: 100%;
  top: 0;
  z-index: 2;
  /* background-color: #ffffff; */
  transition: all 0.2s ease-in-out;
  /* background-color: #ffffff80; */
  /* backdrop-filter: blur(5px); */
  padding: 52px 0 0 66px;
}

.header-container-terms {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dfdfdf;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 2;
  background-color: #ffffff;
  transition: all 0.2s ease-in-out;
}
.header-logo {
  /* padding: 1rem;  */
  width: 55vw;
  /* height: calc(4vh + 2rem); */
  /* border-right: 1px solid #dfdfdf; */
}

.header-main-button {
  margin: 0 16px 0 0 !important;
  animation: none !important;
  font-size: 18px !important;
  font-size: 700 !important;

  background: linear-gradient(
    223.15deg,
    #ffdf00 18.17%,
    #f4c430 84.04%
  ) !important;
  border-radius: 8px !important;
  transition: all 0.3s ease !important;
  color: #0b0b0b !important;
}
.header-main-button:hover {
  background: #222831 !important;
}
.header-links {
  /* display: none; */
  flex: 1;
  font-size: 0.9em;
  color: rgba(0, 0, 0, 0.8);
  padding: 0 2em;
}
.header-links span {
  margin: 0 2.2em;
  cursor: pointer;
}
.header-social {
  display: none;
  align-items: center;
  justify-content: space-evenly;
  /* border-left: 1px solid #dfdfdf; */
  padding: 0;
  width: 22vw;
  height: calc(6vh + 3rem);
}
.header-social-icon {
  margin: 0;
  height: 30px;
  width: 30px;
  text-decoration: none;
}
.header-menu {
  flex: 1;
  text-align: right;
}
.header-menu-icon {
  margin-right: 1rem;
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.footer-two {
  color: #ffffff99;
}
.footer-container {
  color: #ffffff99;
  scroll-snap-align: start;

  display: flex;
  flex-direction: column;
  /* background: radial-gradient(100% 448.16% at 0% 0%, #303030 0%, #000000 100%); */
  /* background-color: #FFFFFF; */
  background: linear-gradient(206.15deg, #2b2b2b -0.09%, #0b0b0b 100%);

  padding: 2rem;
  font-size: 0.8em;
}
.footer-one {
  color: #ffffff99;
  font-size: 1em;
  width: 100%;
  text-align: center;
}
.footer-location {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
  color: #ffffff99;
}
.footer-location-icon {
  margin: 1rem 0.5rem;
  height: 20px;
  width: 20px;
}
.footer-one span {
  margin: 0 0.5rem;
  color: #ffffff99;
}
.footer-center {
  margin-top: 2em;
  text-align: center;
}
.footer-two {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
}
.footer-link {
  width: 100%;
  padding: 0.5rem;
  /* color: rgba(255, 255, 255, 0.6); */
  color: #ffffff99;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
}

.drawer-name-header {
  padding: 14px 24px;
  position: absolute;
  z-index: 1;
  /* background-image: linear-gradient(120deg, #fdfbfbb8 0%, #ebedee78 100%); */
  /* background-color: #fdfbfbb8; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.drawer-name-header p {
  margin: 0;
  font-weight: 400;
  font-size: 1em;
}
.drawer-name-header p span {
  text-transform: capitalize;
}

.drawer-name-header .views-count {
  background-color: #22283199;
  color: white;
  padding: 10px 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
}

.drawer-name-header .views-count img {
  margin-right: 6px;
}

.drawer-header {
  display: flex;
  justify-content: center;
  padding: 1rem;
  border-bottom: solid #121212;
}
.drawer-back-btn {
  position: absolute !important;
  top: 0;
  left: 0;
  height: 12.2vw;
  width: 12.2vw;
  padding: 0.1rem !important;
}
.drawer-logo {
  width: 55vw;
  height: 12.2vw;
}

.home-wrapper {
  width: 100%;
  max-width: 1800px;
  margin: 100px auto 0 auto;
}
.home-wrapper .announcement-banner {
  padding: 26px 0;
  background-image: url(../assets/images/announcement-bg.webp);
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-wrapper .announcement-banner p {
  margin: 0 0 0 30px;
  font-weight: 600;
  font-size: 24px;
  color: #ffffff;
}
.home-main {
  display: flex;
  flex-direction: column;
}
.home-main-one {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 103px 242px 352px 242px;
}
.cta-container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}
.cta-button {
  background: linear-gradient(223.15deg, #ffdf00 18.17%, #f4c430 84.04%);
  padding: 15px 60px;
  color: #0b0b0b;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  transition: background-color 0.2s ease-in-out; /* Smooth background color change with linear easing */
}
.cta-button-primary {
  background-color: #ff4a01;
  padding: 15px 52px;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 26px;
  transition: background-color 0.2s ease-in-out;
}
/* .cta-button:hover, .cta-button-primary:hover{
    background-color: #ffffff;
    color:#1C1C1C;
    border: 0.5px solid #FF4A01;

   
} */

.home-main-one.ai-card {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  padding: 1em 0;
}

.home-main-one.ai-card .mui-image-wrapper {
  text-align: center !important;

  display: block !important;
  background-color: inherit;
}

.home-main-one h1 {
  font-size: 150px;
  font-style: italic;
  font-weight: 700;
  line-height: 142px;
  letter-spacing: -0.04em;
  text-align: left;
  color: #ffffff;
  margin: 1.5rem;
}
#step-1-home-main-button,
.home-main-button {
  border-radius: 18px;
  font-size: 1em;
  margin: 0 1.5rem 2rem;
  mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300% 100%;
  -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300%
    100%;
  background-repeat: no-repeat;
  animation: shimmer 2.5s infinite;
  width: max-content;
  padding: 0.8rem 2.4rem;
}
.home-main-btn-icon {
  margin: 0 0.3rem -0.1rem;
  height: 15px;
  width: 15px;
}
.home-main-two {
  flex: 1.2;
  background-color: #ffe9e0;
}
.home-main-gif {
  width: 100%;
  margin: 2rem auto;
}
.home-types {
  display: flex;
  align-items: center;
  flex-direction: column;
  border-top: 1px solid #dfdfdf;
}
.home-type {
  flex: 1;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dfdfdf;
  width: 100%;
  padding: 1.5rem 3rem;
}
.home-types .home-types-link {
  text-decoration: none;
}
.home-types .home-types-link span {
  color: #ff4a01;
}
.home-type-icon {
  margin-right: 2rem;
  height: 4em;
  width: 4em;
}
.home-type-content span {
  font-size: 1.2em;
  font-weight: 500;
}
.home-type-content p {
  margin: 0.2rem 0;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.9em;
}
.home-content {
  display: flex;
  flex-direction: column;
}
.home-content-gif-container {
  flex: 1;
}
.home-content-gif {
  width: 100%;
  margin: auto;
}
.home-content-text {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}
.home-content-text div {
  text-align: center;
}
.home-content-text span {
  font-weight: 500;
  font-size: 1.8em;
}
.home-content-text p {
  font-size: 1.1em;
  color: rgba(0, 0, 0, 0.6);
}
.home-content.ms-section {
  background-image: url(../assets/images/ms-section-bg.webp);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.home-content.ms-section .home-ms-for-startups {
  margin: 0 0 8px 0;
  color: #0f0a18;
}
.home-content.ms-section span {
  color: #8560c5 !important;
}
.home-verify-check {
  margin-bottom: 0.8rem;
  margin-left: 0.2rem;
  height: 100%;
  width: 1.5rem;
}
.home-coming-soon {
  color: #ff4a01 !important;
  font-size: 1.25em !important;
  font-weight: 500;
  margin: 0.3rem 0;
}
.home-partner-container {
  text-align: center;
  width: 90%;
  margin: 4rem auto;
}
.home-partner-container span {
  font-weight: 500;
  font-size: 1.5em;
}
.home-partner {
  margin: 2rem auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.home-partner-logo {
  width: 38%;
  margin: 1rem;
}
.home-request-access-container {
  background: #fffefb;
  border-top: 1px solid #dfdfdf;
}
.home-request-access {
  background: #ff4a01;
  border: 1px solid #dfdfdf;
  border-radius: 50px;
  width: 92%;
  margin: 3rem auto;
  color: #ffffff;
  padding: 3rem 0 1rem;
  text-align: center;
  position: relative;
  overflow: hidden;
}
.home-request-title {
  margin: 0 1.6rem 2rem;
  font-size: 2em;
}
.home-request-img1 {
  position: absolute;
  left: -1rem;
  bottom: 6rem;
  height: 5rem;
  width: 5rem;
}
.home-request-img2 {
  position: absolute;
  bottom: -2.5rem;
  right: 0;
  height: 9rem;
  width: 9rem;
}
.home-animation-container {
  height: 1.5em;
  overflow: hidden;
}
.home-animation-plain-text {
  display: inline;
  float: left;
  margin: 0;
}
.home-animation-content {
  clear: both;
  margin-top: 0;
  padding-left: 4.8em;
  margin-left: 0px;
  font-family: "Poppins", sans-serif, -apple-system, BlinkMacSystemFont;
  text-align: left;
  list-style: none;
  -webkit-animation-name: change;
  -webkit-animation-duration: 8s;
  -webkit-animation-iteration-count: infinite;
  animation-name: change;
  animation-duration: 8s;
  animation-iteration-count: infinite;
}
.home-animation-text {
  margin: 0;
  color: #0b0b0b;
  font-size: 100px !important;
  font-weight: 700 !important;
  font-style: italic;
  padding-right: 12px !important;
  padding-bottom: 10px;
  background: linear-gradient(
    101.43deg,
    #ffffff 1.17%,
    rgba(255, 255, 255, 0.5) 34.78%,
    #ffffff 55.97%,
    rgba(255, 255, 255, 0.74) 75.69%,
    rgba(255, 255, 255, 0.74) 79.76%
  );
  mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300% 100%;
  -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300%
    100%;
  background-repeat: no-repeat;
  animation: shimmer 2.5s infinite;
  background-clip: text;
  -webkit-background-clip: text;
}

.request-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  position: relative;
}
.request-wrapper-waitlist {
  display: flex;
  flex-direction: column;
  height: 100%;
  /* overflow: hidden; */
  overflow-y: auto;
  position: relative;
}
.request-hero {
  width: 100vw;
  height: 75vw;
}
.request-hero img {
  object-fit: cover;
}
.request-main-text {
  padding: 1rem 1.5rem 0.5rem;
  font-size: 1.05em;
}
.request-input-prefix {
  color: #ffffff;
  border-right: 1px solid #dfe0e2;
  padding: 0.3rem;
  margin-right: 0.5rem;
}
.request-footer-btn {
  /* background: #ffffff; */
  background-color: #121212;
  /* border: 1px solid #dfdfdf; */
  padding: 1.5rem;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  /* -webkit-box-shadow: 0px -4px 3px rgba(220, 220, 220, 0.75);
  -moz-box-shadow: 0px -4px 3px rgba(220, 220, 220, 0.75);
  box-shadow: 0px -4px 3px rgba(220, 220, 220, 0.75); */
}

.progressbar-col .RCP {
  width: 100% !important;
}

.progressbar-col .RCP svg {
  width: 150px;
  height: 150px;
}

.request-type {
  display: flex;
  align-items: center;
  /* background: #eeeeee; */
  background-color: #1f1f1f;
  color: #ffffff;
  /* border: 1px solid #d2d2d2; */
  /* border: 1px solid; */
  border: none;
  border-image: linear-gradient(to right, #f00, #ff0, #0f0, #0ff, #00f, #f0f) 1
    100%;

  border-radius: 8px;
  /* color: #40464f; */

  padding: 1rem 1.4rem;
  margin: 1rem 0;
  cursor: pointer;
}

.other-expertise .MuiOutlinedInput-input.MuiInputBase-input {
  background-color: #242424;
  color: #bdbfc1;
}
/* .form-input .MuiOutlinedInput-input.MuiInputBase-input {
  background-color: #242424 !important;
  color: #bdbfc1 !important;
  border-radius: 10px;
} */

.form-control
  .MuiInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl {
  color: #bdbfc1 !important;
}

.form-input
  .MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined.MuiFormLabel-root.MuiFormLabel-colorPrimary {
  color: #bdbfc1 !important;
}

.MuiBackdrop-root.drawer-backdrop-blur {
  background-color: #000 !important;
}
.form-input
  .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined {
  color: #bdbfc1 !important;
}

.form-input
  .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input {
  color: #bdbfc1 !important;
}

.form-input
  .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl {
  background-color: #242424 !important;
  color: #bdbfc1 !important;
  border-radius: 10px;
}
.form-control .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
  color: #bdbfc1;
}
.form-input
  .MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root.form-input
  .form-input
  .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedStart {
  background-color: #242424 !important;
  color: #bdbfc1 !important;
}

.otp-input .MuiOutlinedInput-input.MuiInputBase-input {
  text-align: center;
}

.otp-input .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary {
  background: transparent;
  color: #000000;
  border: 1px solid transparent;

  /* border-bottom: 2px solid #000000; */
}

/* .form-input
  .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl {
  background-color: #242424 !important;
  color: #bdbfc1 !important;
} */

.f .waitlist-bg-div {
  position: absolute;
  height: 100%;
  width: 100%;
  background: url(../assets/images/waitlist-bg.png);
  background-size: contain;
  z-index: -1;
}
/* waitlist */
.waitlist-top-section {
  /* background-color: #ff4a01; */
  text-align: center;
  height: 100%;
}
.waitlist-top-section .waitlist-top-section-para {
  margin: 24px 0 -12px 0;
  color: #fff;
}
.waitlist-top-section .waitlist-top-section-para img {
  margin-bottom: -3px;
}
.waitlist-top-section .card-div {
  position: relative;
  width: 100%;
  margin-top: 5%;
}
.waitlist-top-section .card-div .name {
  position: absolute;
  margin: 0;

  color: #222831;
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
}
.waitlist-top-section .polygon-div {
  background: url(../assets/images/waitlist-polygon.svg);
  background-repeat: no-repeat;
  background-size: 90%;
  background-position: center center;
  position: relative;
  width: 100%;
  height: 500px;
}
.waitlist-top-section .polygon-div .white-line {
  margin-bottom: 40px !important;
}
.waitlist-bottom-section {
  padding-top: 60px;
  height: 100%;
  background: radial-gradient(
    99.17% 99.17% at 50% 50%,
    #434343 0%,
    #121212 100%
  );
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.waitlist-bottom-section p,
.waitlist-top-section .polygon-div p {
  margin: 0;
  line-height: 1;
}
.waitlist-bottom-section .white-line,
.waitlist-top-section .polygon-div .white-line {
  margin-bottom: 12px;
  font-size: 24px;
  font-weight: 600;
}
.waitlist-bottom-section .queue-number,
.waitlist-top-section .polygon-div .queue-number {
  margin-bottom: 12px;
  font-size: 40px;
  font-weight: 600;
  color: #ff4a01;
}
.waitlist-bottom-section .grey-line,
.waitlist-top-section .polygon-div .grey-line {
  color: #c8c8c8;
  margin-bottom: 20px;
}
.waitlist-bottom-section .early-access-div {
  margin: 16px;
  padding: 16px;
  /* border: 1px solid #6f7379; */
  border-radius: 16px;
}
.waitlist-bottom-section .early-access-div .text-div {
  text-align: center;
}
.waitlist-bottom-section .early-access-div .text-div .white-line {
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: 400;
}
.waitlist-bottom-section .early-access-div .social-icon-div {
  padding: 20px 0 12px 0;
  text-align: center;
}
.selected-type {
  /* background: linear-gradient(316.22deg, #222831 0%, #40464f 97.96%); */
  background: radial-gradient(
    99.17% 99.17% at 50% 50%,
    #dadada 0%,
    #121212 100%
  );
  color: #272727;
}

/* .otp-input-div
  .MuiOutlinedInput-root
  .MuiInputBase-root
  .MuiInputBase-colorPrimary
  .css-ettpk6-MuiInputBase-root-MuiOutlinedInput-root {
  border-color: blue !important;
  border-width: 2px !important;
} */

.request-type-icon {
  margin-right: 1.4rem;
  height: 3.4rem;
  width: 3.4rem;
}
.request-subtype-wrapper {
  font-weight: 700;
  text-align: center;
  font-size: 26px;
  color: #cfcfcf;
  padding: 1.5rem;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.request-sub-types {
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}
.request-subtype-wrapper::-webkit-scrollbar,
.request-chat::-webkit-scrollbar,
.request-get-access-wrapper::-webkit-scrollbar,
.profile-carousel-container::-webkit-scrollbar,
.service-list::-webkit-scrollbar,
.service-detail-container::-webkit-scrollbar,
.service-slots::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
  width: 0;
  height: 0;
}
.request-sub-type {
  /* margin-bottom: 1rem;
  margin-right: 4%; */
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* background-color: #ffffff; */
  background: radial-gradient(
    99.17% 99.17% at 50% 50%,
    #434343 0%,
    #121212 100%
  );
  /* border: 1.3px solid; */

  /* border-image-source: linear-gradient(
    152.68deg,
    #ffffff 5.46%,
    rgba(255, 255, 255, 0) 32.84%,
    rgba(255, 255, 255, 0) 71.3%,
    #ffffff 100%
  ); */

  border-radius: 12px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  word-break: keep-all;
  text-align: center;
}
.selected-sub-type {
  /* background: linear-gradient(316.22deg, #222831 0%, #40464f 97.96%); */
  background: radial-gradient(
    99.17% 99.17% at 50% 50%,
    #dadada 0%,
    #121212 100%
  );
}
.request-sub-type-icon {
  height: 2.6rem;
  width: 2.6rem;
}
.request-user-img {
  height: 9rem;
  width: 9rem;
  margin: 2rem auto 0.6rem;
  border-radius: 12px;
  overflow: hidden;
}
.request-user-name {
  font-size: 1.3em;
  font-weight: 700;
  color: #cfcfcf;
}
.request-user-types {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 1rem 1.5rem;
  justify-content: center;
}
.request-user-type {
  margin: 0.3rem;
  padding: 7px 13px;
  border-radius: 10px;
  background: #313131;
  color: #cfcfcf;
  font-size: 0.85em;
  font-weight: 500;
  box-shadow: 0px 4px 4px rgba(131, 131, 131, 0.13);
  border-radius: 44px;
  text-transform: capitalize;
}
.request-footer-text {
  background: linear-gradient(
    101.43deg,
    #ffffff 1.17%,
    rgba(255, 255, 255, 0.5) 34.78%,
    #ffffff 55.97%,
    rgba(255, 255, 255, 0.74) 75.69%,
    rgba(255, 255, 255, 0.74) 79.76%
  );
  mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300% 100%;
  -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300%
    100%;
  background-repeat: no-repeat;
  animation: shimmer 2.5s infinite;
  background-clip: text;
  -webkit-background-clip: text;
  flex: 1.6;
  padding-right: 1rem;
  color: #fff;
  font-size: 0.95em;
}
.request-get-access-wrapper {
  text-align: center;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.profile-wrapper {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  filter: drop-shadow(0px 4px 4px rgba(255, 74, 1, 0.13));
  -webkit-filter: drop-shadow(0px 4px 4px rgba(255, 74, 1, 0.13));
}
.profile-category-chip {
  font-size: 0.85em;
  padding: 0.1rem 0.6rem;
  margin: 0.6rem 0.6rem 0.6rem 0;
  background: linear-gradient(180deg, #585858 0%, #414141 100%);
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.31);
  border-radius: 53px;
  color: #fff;
  text-transform: capitalize;
}
.profile-carousel-container {
  position: relative;
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.profile-carousel {
  margin: 1rem 1rem 0 0;
  height: 100px;
  width: 180px;
  background-color: #666;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}
.profile-carousel-arrow {
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(55px);
  width: 3rem;
  height: 3rem;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
  cursor: pointer;
}
.request-chat {
  padding: 1rem;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  flex: 1;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.invite-wrapper {
  background: #ffffff;
  box-shadow: 0px 50px 55px #eee;
  border-radius: 10px;
  width: 86%;
  margin: auto;
  padding: 1rem;
  min-height: 300px;
}
.invite-main {
  width: 72%;
  padding: 1.2rem 0.6rem;
  margin: 1rem auto 0;
  background-repeat: no-repeat;
  background-size: contain;
  mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300% 100%;
  -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300%
    100%;
  background-repeat: no-repeat;
  animation: shimmer 2.5s infinite;
}
.invite-content {
  text-align: center;
  font-size: 1.1em;
}
.invite-by {
  color: #6f7379;
}
.invite-btn-wrapper {
  margin-top: 2rem;
  padding: 1rem 0;
  border-top: 1px solid #dfe0e2;
  position: relative;
}
.invite-desc {
  margin-top: 0.5rem;
  font-size: 0.8em;
  color: #6f7379;
}
.invite-cutout {
  position: absolute;
  top: -1rem;
  left: -2rem;
  height: 2rem;
  width: 2rem;
  background-color: #eee;
  border-radius: 1rem;
}

.service-header {
  font-weight: 600;
  font-size: 1.2em;
  text-transform: capitalize;
}
.service-wrapper {
  border-bottom: 1px solid #dfe0e2;
  display: flex;
  align-items: center;
  padding: 0.6rem 1.5rem;
  color: #6f7379;
  font-weight: 400;
}
.service-tab {
  padding: 0.4rem 0.8rem;
  border-radius: 40px;
  background-color: #222831;
  color: #fff;
  font-weight: 600;
}
.service-list {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100%;
  height: min-content;
  margin-top: 1rem;
  padding-bottom: 2rem;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.service-list-header {
  font-weight: 500;
  margin: 0;
  text-transform: capitalize;
}
.service-item {
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 1.5rem;
  display: flex;
  overflow: hidden;
  cursor: pointer;
}
.service-img {
  min-width: 8rem;
  max-width: 8rem;
  height: 7rem;
  margin-right: 1rem;
}
.service-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.service-content h4 {
  font-weight: 500;
  font-size: 1.1em;
  margin: 0;
}
.service-content p {
  margin: 0 0 5px;
  font-size: 0.9em;
  color: #6f7379;
}
.service-content span {
  font-weight: 600;
  color: #ff4a01;
}
.service-location {
  background-color: #fff;
  border-radius: 10px;
  margin: 1rem auto;
  padding: 1rem;
  font-size: 0.95em;
}
.session-slots-div::-webkit-scrollbar {
  display: none;
}
.time-selector {
  margin: 0 8px 8px 0;
  padding: 9px 12px;
  font-size: 14px;
  font-weight: 500;
  color: #222831;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 10px;
  width: fit-content;
}
.time-selector-active {
  color: #ff4a01;
  background: #ffe9e0;
  border: 1px solid #ff4a01;
}
.service-session {
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 1.2rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.service-session #back-arrow-path {
  stroke: #fff !important;
}
.service-session-arrow {
  transform: rotate(180deg);
  height: 2.6rem;
  width: 2.6rem;
}
.service-session-arrow path {
  stroke: #6f7379;
}
.service-detail-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}
.service-detail-container {
  margin: 5%;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.service-slot-dropdown {
  border-radius: 4px;
  border: 1px solid #dadada !important;
}
.service-slot-dropdown svg,
.service-slot-selected svg {
  fill: #ff4a01 !important;
}
.service-slot-selected {
  border-radius: 4px;
  border: 1px solid rgba(218, 218, 218, 0.09) !important;
}
.service-back-btn #back-arrow-path {
  stroke: #fff;
  fill: #fff;
}
.service-slots {
  display: flex;
  width: 100%;
  margin: 0.3rem 0;
  overflow-x: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.video-player video {
  object-fit: contain !important;
}

.drawer-backdrop-text {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100vw - 75vh);
  /* height: 100vh; */
  z-index: 1;
}
.drawer-backdrop-blur {
  background: linear-gradient(
    347.89deg,
    rgba(255, 255, 255, 0.43) 0%,
    #fefaec 100%
  );
}
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .drawer-backdrop-blur {
    background: linear-gradient(
      347.89deg,
      rgba(255, 255, 255, 0.43) 0%,
      #fefaec 100%
    );
    box-shadow: -7.43419px 0px 74px rgba(255, 74, 1, 0.3);

    -webkit-backdrop-filter: blur(6.73304px);
    backdrop-filter: blur(6.73304px);
  }
}

.shimmer {
  color: grey;
  display: inline-block;
  mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300% 100%;
  -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300%
    100%;
  background-repeat: no-repeat;
  animation: shimmer 2.5s infinite;
  background-color: #ddd;
  width: 100%;
  height: 100%;
}
@keyframes shimmer {
  100% {
    -webkit-mask-position: left;
  }
}

@-webkit-keyframes opacity {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
/* @-webkit-keyframes change {
  0%,
  25%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  40%,
  50% {
    transform: translate3d(0, -16.66%, 0);
  }
  65%,
  75% {
    transform: translate3d(0, -33.32%, 0);
  }
  90%,
  100% {
    transform: translate3d(0, -49.98%, 0);
  }
} */
@-webkit-keyframes change {
  0%,
  25%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  25%,
  25% {
    transform: translate3d(0, -25%, 0);
  }
  50%,
  50% {
    transform: translate3d(0, -50%, 0);
  }
  75%,
  75% {
    transform: translate3d(0, -75%, 0);
  }
  98%,
  100% {
    transform: translate3d(0, -98%, 0);
  }
}
@keyframes opacity {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
@keyframes change {
  0%,
  25%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  25%,
  25% {
    transform: translate3d(0, -25%, 0);
  }
  50%,
  50% {
    transform: translate3d(0, -50%, 0);
  }
  75%,
  75% {
    transform: translate3d(0, -75%, 0);
  }
  98%,
  100% {
    transform: translate3d(0, -98%, 0);
  }
}
/* @keyframes change {
  0%,
  12.66%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  16.66%,
  29.32% {
    transform: translate3d(0, -16.66%, 0);
  }
  33.32%,
  45.98% {
    transform: translate3d(0, -33.32%, 0);
  }
  49.98%,
  62.64% {
    transform: translate3d(0, -49.98%, 0);
  }
  66.64%,
  79.3% {
    transform: translate3d(0, -66.64%, 0);
  }
  83.3%,
  95.96% {
    transform: translate3d(0, -83.3%, 0);
  }
} */

.ripple {
  animation: ripple 1.5s infinite;
}
@keyframes ripple {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 0.3;
  }
}

/* session plan screen */
.plans-list-item {
  margin-bottom: 14px;
  padding: 12px 16px;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  border: 1px solid #ffffff;
  cursor: pointer;
}
.plans-list-item h3,
.plans-list-item p {
  margin: 0;
}
.plans-list-item img {
  display: none;
}
.plans-list-item .top-section {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.plans-list-item .bottom-section {
  margin-top: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 0;
  overflow-x: hidden;
  transition: all 0.2s ease-in-out;
}
.selected-plans-list-item {
  border: 1px solid #ff4a01;
}
.selected-plans-list-item img {
  display: block;
}
.selected-plans-list-item .bottom-section {
  margin-top: 20px;
  height: 40px;
}

/* expert page */
.expert-wrapper .expert-main {
  background-image: url(../assets/images/expert-bg.webp);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
}
.expert-wrapper .expert-main .home-main-one {
  align-items: center;
  padding: 4em 0;
  position: relative;
}
.expert-wrapper .expert-main .home-main-one h1 {
  position: relative;
  width: fit-content;
}
.expert-wrapper .expert-main .home-main-one h1::after {
  content: url(../assets/images/verified-tick.webp);
  position: absolute;
  top: 0;
  right: -10px;
  transform: translate(90%, -25%);
}
.expert-wrapper .expert-main .home-main-one h1 .underline {
  text-decoration: underline;
  text-decoration-color: #ff4a01;
}

.expert-wrapper .expert-main .home-main-one .animation-div-right {
  width: 160px;
  position: absolute;
  top: 14%;
  right: 8%;
}

.expert-wrapper .expert-main .home-main-one .animation-div-left {
  position: absolute;
  top: 2%;
  left: 3%;
}
.expert-wrapper .expert-main .home-main-one .animation-div-center {
  width: 60%;
}

.expert-types .expert-type-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.expert-wrapper .coming-soon-text {
  padding: 10px 20px;
  width: fit-content;
  font-weight: 500;
  font-size: 24px;
  color: #ff4a01;
  background: rgba(255, 74, 1, 0.2);
  border-radius: 6px;
}

/* brand */

.brand-wrapper .animation-div-left {
  width: 160px;
  top: 25% !important;
}

@media only screen and (min-width: 768px) {
  .body {
    font-size: 2rem;
  }
  .header-logo {
    /* padding: 0; */
    /* width: auto;
    height: calc(5vh + 2rem); */
    width: 226.3px;
  }
  .header-logo img {
    object-position: left;
  }
  .header-links {
    font-weight: 400;
    font-size: 20px;
    text-transform: capitalize;
  }
  .header-links a.active {
    font-weight: 600;
    font-size: 20px;
    color: #ff4a01;
    border-bottom: 4px solid #ff4a01;
  }
  .header-links a {
    color: #000000;
    text-decoration: none;
  }
  .header-links a:hover {
    color: #ff4a01;
  }
  .header-links,
  .header-social {
    display: flex;
    justify-content: flex-start;
    gap: 24px;
  }
  .header-menu {
    display: none;
  }
  .footer-container {
    padding: 4rem;
    flex-direction: row;
  }
  .footer-one {
    text-align: left;
    width: 75%;
  }
  .footer-location {
    justify-content: flex-start;
  }
  .footer-center {
    margin-top: 0;
    text-align: left;
    width: 70%;
  }
  .footer-two {
    align-items: center;
    flex-wrap: wrap;
    width: 80%;
    flex-direction: row;
    text-align: left;
  }
  .footer-link {
    width: 45%;
    padding-bottom: 0;
  }

  .home-main,
  .home-content {
    flex-direction: row;
  }
  .home-main-gif {
    width: 38vw;
  }
  .home-main-one h1 {
    font-size: 3.25em;
    margin: 0 3.5rem 2rem;
  }
  #step-1-home-main-button,
  .home-main-button {
    font-size: 1.2em;
    margin: 0 3.5rem;
  }
  .home-types {
    flex-direction: row;
    border-bottom: 1px solid #dfdfdf;
  }
  .home-type {
    border-right: 1px solid #dfdfdf;
    min-height: 10rem;
    padding: 2rem 5rem;
    border-bottom: none;
  }
  .home-type-content span {
    font-size: 1.25em;
  }
  .home-type-content p {
    font-size: 0.85em;
  }
  .home-content-gif-container {
    padding: 2rem;
  }
  .home-content-gif {
    margin: 2rem auto;
    width: 36vw;
  }
  .home-content-text div {
    text-align: left;
    padding-right: 20%;
    padding: 10%;
  }
  .home-content-text span {
    font-size: 2.8em;
  }
  .home-content-text p {
    font-size: 1.6em;
  }
  .home-partner-container {
    width: 60%;
  }
  .home-partner-container span {
    font-size: 2.8em;
  }
  .home-partner {
    width: 75%;
  }
  .home-partner-logo {
    flex: 1;
    margin: 1rem 2rem;
  }
  .home-request-access {
    width: 75%;
    padding: 3rem 0;
    margin: 5rem auto;
  }
  .home-request-title {
    margin: 0 6rem 2rem;
    font-size: 3.5em;
  }
  .home-request-img1 {
    bottom: -3rem;
    height: 14rem;
    width: 14rem;
  }
  .home-request-img2 {
    height: 18rem;
    width: 18rem;
    right: -2rem;
    bottom: -5rem;
  }

  .drawer-backdrop-text {
    display: block;
  }
  .request-hero {
    width: 100%;
    height: 40%;
  }
  .drawer-back-btn {
    height: 2.2vw;
    width: 2.2vw;
  }
  .drawer-logo {
    height: 2.3vw;
  }
}

@media only screen and (max-width: 767px) {
  /* header*/

  .header-logo {
    padding: 0px;
  }
  .header-container {
    padding: 19px 3% 0px 3%;
    justify-content: space-between;
    align-items: center;
  }

  .about-page {
    padding: 20% 3% 0 3% !important;
    flex-wrap: wrap;
    background-image: url("../v2_assets/about_bg_mob.webp") !important;
  }

  .about-detail-1,
  .about-detail-2 {
    font-size: 20px !important;
    margin-top: 150px !important;

    line-height: 30px !important;
    letter-spacing: -0.04em !important;
  }

  .about-title {
    margin-top: 10px !important;
  }

  .heading-small {
    font-size: 20px !important;
    line-height: 27px !important;
    letter-spacing: -0.04em !important;
  }
  .heading-large {
    font-size: 40px !important;
    line-height: 47.99px !important;
    letter-spacing: -0.04em !important;
  }
  .about-title {
    font-size: 68px !important;
    line-height: 81.58px !important;
    letter-spacing: -0.04em !important;
    text-align: left !important;
  }
  .header-container .button-div {
    position: absolute;
    left: 80%;
    transform: translateX(-50%);
  }
  .header-container .button-div .header-main-button {
    padding: 6px 6px 6px 12px !important;
    font-size: 14px !important;
  }
  .header-logo {
    border-right: none;
  }
  .header-links-mobile {
    list-style: none;
    padding: 0;
  }
  .header-links-mobile li {
    padding: 16px 12px;
  }
  .header-links-mobile li a {
    font-size: 1rem;
    font-weight: 400;
    color: #000000;
    text-transform: capitalize;
    text-decoration: none;
  }
  .header-links-mobile li a.active {
    color: #ff4a01;
    font-weight: 600;
  }
  .home-wrapper .announcement-banner {
    background-image: url(../assets/images/announcement-bg-mobile.webp);
  }
  .home-wrapper .announcement-banner p {
    margin-left: 16px;
    font-size: 1rem;
    width: 75%;
  }
  .home-content.ms-section {
    background-image: url(../assets/images/ms-section-bg-mobile.webp);
    flex-direction: column-reverse;
  }
  .home-content.ms-section .home-content-text .home-ms-for-startups {
    margin-top: 50px;
  }
  .home-content.ms-section .home-content-gif-container {
    padding-top: 40px;
  }
  .home-animation-text {
    font-size: 38px !important;
  }
  /* expert */
  .expert-wrapper .expert-main .home-main-one h1 br {
    display: none;
  }

  .expert-wrapper .expert-main .home-main-one h1::after {
    top: -20px;
    right: 16px;
    transform: scale(0.5);
  }

  .expert-wrapper .expert-main .home-main-one .animation-div-right {
    width: 70px;
    top: 4%;
    right: 2%;
  }
  .expert-wrapper .expert-main .home-main-one .animation-div-left {
    top: -3%;
    left: 1%;
    width: 30%;
  }
  .expert-wrapper .expert-main .home-main-one .animation-div-center {
    width: 100%;
  }

  .expert-wrapper .coming-soon-text {
    margin: 16px auto;
  }

  /* brands */
  .brand-wrapper .animation-div-left {
    top: 2% !important;
    left: 1% !important;
    width: 20% !important;
  }

  /* centre */
  /* brands */
  .centre-wrapper .animation-div-left {
    top: 2% !important;
    left: 1% !important;
    width: 30% !important;
  }

  /* waitlist screen */
  .waitlist-top-section .polygon-div {
    height: 400px;
  }

  /* ofline */
  .plans-list-item .bottom-section {
    display: block;
    height: 80px;
  }
  .plans-list-item .bottom-section p {
    margin-bottom: 10px;
  }

  /* footer */
  .footer-one .parent-company-title {
    justify-content: center;
    margin-bottom: 6px;
  }
  .footer-one .parent-company-title svg {
    display: none;
  }
  .parent-company-title p {
    color: #ffffff99 !important;
  }
}

/* Team */

.team-drawer-div {
  background-color: #ff4a01;
  height: 100%;
  position: relative;
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
}
.team-drawer-div.yash-bg {
  background-image: url(../assets/images/yt.jpeg);
}
.team-drawer-div.siddhesh-bg {
  background-image: url(../assets/images/sg.jpg);
}
.team-bg-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background: url(../assets/images/bg-overlay.png);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: contain;
  z-index: 0;
}
.team-drawer-div .content-div {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.team-drawer-div .content-div .title .name {
  display: flex;
  align-items: center;
}
.team-drawer-div .content-div .title .name h2 {
  margin: 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
}
.team-drawer-div .content-div .title .tag {
  margin: 8px auto 0 auto;
  padding: 6px 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  background: linear-gradient(180deg, #585858 0%, #414141 100%);
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.31);
  border-radius: 53px;
  width: fit-content;
}
.team-drawer-div .content-div .social-div {
  margin-top: 20px;
}
.team-drawer-div .content-div .social-div .connect-para {
  position: relative;
  margin: 0 auto 16px auto;
  color: #6f7379;
  width: fit-content;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}
.team-drawer-div .content-div .social-div .connect-para::before {
  position: absolute;
  content: "";
  border-top: 1px solid #222831;
  width: 110%;
  height: 0px;
  left: -125%;
  top: 50%;
  transform: translateY(50%);
}
.team-drawer-div .content-div .social-div .connect-para::after {
  position: absolute;
  content: "";
  border-top: 1px solid #222831;
  width: 110%;
  height: 0px;
  right: -125%;
  top: 50%;
  transform: translateY(50%);
}

.team-drawer-div .content-div .social-div .icon-div .social-btn {
  margin-right: 10px;
  padding: 0;
  border: 1px solid #222831;
  border-radius: 10px;
  width: 52px;
  height: 52px;
}
.team-drawer-div .content-div .social-div .icon-div .social-btn:last-child {
  margin-right: 0;
}

.team-drawer-div .drawer-footer {
  margin: 30px auto 20px;
}

/* onboarding steps */

.onboarding-step {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

/* .onboarding-step.onboarding-step-1 {
  background: url(../assets/images/onboarding-step-1.svg);
  background-position: center 60%;
  background-repeat: no-repeat;
  background-size: cover;
} */
/* .onboarding-step.onboarding-step-2 {
  background: url(../assets/images/onboarding-step-2.svg);
  background-position: center 100%;
  background-repeat: no-repeat;
  background-size: cover;
} */

.onboarding-step .form-control {
  margin-bottom: 16px;
  padding: 10px 12px;
  background-color: #242424;
  border-radius: 10px;
  color: #bdbfc1;
}
.onboarding-step .form-control p {
  margin: 0;
  margin-bottom: 10px;
  color: #bdbfc1;
  font-size: 12px;
}
.onboarding-step .form-control .MuiFormControl-root {
  margin: 0;
}

.onboarding-step .form-control .mui-image-wrapper {
  margin-left: 16px;
}

.onboarding-step.onboarding-step-5 {
  background: url(../assets/images/stage-one-complete.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
}

.onboarding-step.onboarding-step.onboarding-step-7 {
  background: url(../assets//images/health-score.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  margin-bottom: -110px;

  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.onboarding-step.onboarding-step.onboarding-step-3 .scroll-input-div {
  /* background: rgb(238, 238, 238); */
  /* background: radial-gradient(
    99.17% 99.17% at 50% 50%,
    #dadada 0%,
    #121212 100%
  ); */
  background: #1f1f1f;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 4px;
  position: relative;
  overflow: hidden;
}
.onboarding-step.onboarding-step.onboarding-step-3 .scroll-input-div::before {
  position: absolute;
  content: "";
  top: -10px;
  left: 50%;
  height: 16px;
  width: 16px;
  background-color: #ff4a01;
  transform: translateX(-50%) rotate(45deg);
}
.onboarding-step.onboarding-step.onboarding-step-3
  .scroll-input-div.scroll-input-div-2 {
  flex-direction: column;
  padding: 8px;
}
.onboarding-step.onboarding-step.onboarding-step-3
  .scroll-input-div.scroll-input-div-2::before {
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
}

.onboarding-step .question-card {
  padding: 6% 15%;
  border-radius: 12.9612px;

  background: radial-gradient(
    99.17% 99.17% at 50% 50%,
    #434343 0%,
    #121212 100%
  );
}
.onboarding-step .question-card .question-div {
  /* padding: 20px; */
  /* border-radius: 10px 10px 0px 0px; */

  border-radius: "12.9612px";

  text-align: center;
}
.onboarding-step .question-card .question-div .question-number {
  margin-bottom: 12px;
  height: 24px;
  width: 24px;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #bdbfc1;
  border-radius: 100px;
}
.onboarding-step .question-card .question-div .question {
  margin: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.4;
  color: #ffffff;
}
.onboarding-step .question-card .buttons-div {
  padding: 22px 20px 0px 20px;
  display: flex;
  border-radius: 11.6279px;
  align-items: center;
  justify-content: space-between;
}

.onboarding-step .score-card {
  margin-top: 8%;
  width: 100%;
  /* height: 432px; */
  /* min-height: 432px; */
  text-align: center;
  overflow-y: scroll;
  overflow-x: hidden;
  /* background-color: #ffffff; */
  box-shadow: 0px 22px 20px rgba(0, 0, 0, 0.1);
  border-radius: 26px;
}
.onboarding-step .score-card .indicator {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);
}
.onboarding-step .score-card .indicator p {
  margin: 0;
}
.onboarding-step .score-card .indicator .score {
  font-size: 48px;
  font-weight: 700;
  color: #222831;
}
.onboarding-step .score-card .indicator .tag {
  margin: 14px auto 0 auto;
  padding: 4px 16px;
  width: fit-content;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  text-transform: capitalize;
  border-radius: 100px;
  background-color: #eeeeee;
}
.onboarding-step .score-card .indicator .tag.tag-worst,
.onboarding-step
  .download-screen-bottom
  .score-div
  .progressbar-col
  .indicator
  .tag.tag-worst {
  background-color: #ff0000;
}
.onboarding-step .score-card .indicator .tag.tag-bad,
.onboarding-step
  .download-screen-bottom
  .score-div
  .progressbar-col
  .indicator
  .tag.tag-bad {
  background-color: #ff9635;
}
.onboarding-step .score-card .indicator .tag.tag-good,
.onboarding-step
  .download-screen-bottom
  .score-div
  .progressbar-col
  .indicator
  .tag.tag-good {
  background-color: #8fbf07;
}
.onboarding-step .score-card .indicator .tag.tag-great,
.onboarding-step
  .download-screen-bottom
  .score-div
  .progressbar-col
  .indicator
  .tag.tag-great {
  background-color: #00be2a;
}
.onboarding-step .score-card .score-para {
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.4;
  text-align: center;
  color: #222831;
}

.onboarding-step .form-control p.Mui-error {
  margin-bottom: 0;
  font-size: 12px;
  color: red;
}
.download-screen-middle {
  text-align: center;
  font-weight: 600;
  margin-top: 30;
}
.onboarding-step .download-screen-top {
  text-align: center;
}
.onboarding-step .download-screen-top .mui-image-wrapper {
  margin: auto;
}
.onboarding-step .download-screen-top p.name {
  margin: 14px 0 14px 0;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  color: #cfcfcf;
  text-transform: capitalize;
}
.onboarding-step .download-screen-top .interest-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.onboarding-step .download-screen-top .interest-list .interest-list-item {
  margin: 0 8px 12px 0;
  padding: 8px 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #cfcfcf;
  text-transform: capitalize;
  background: #313131;
  box-shadow: 0px 4px 4px rgba(131, 131, 131, 0.13);
  border-radius: 44px;
}

.onboarding-step.onboarding-step-8 .divider {
  margin: 20px 0;
  box-shadow: none;
  border: none;
  background-color: #dfe0e2;
  /* background: linear-gradient(
    180deg,
    rgba(223, 224, 226, 0) 0%,
    #dfe0e2 50%,
    rgba(223, 224, 226, 0) 100%
  ); */

  height: 1px;
}
.onboarding-step .download-screen-bottom .score-div {
  /* margin-bottom: 18px; */
  padding: 16px 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background: linear-gradient(180deg, #ffffff 0%, #e8e8e8 106.1%); */
  /* border: 1px solid #dee2e7; */
  border-radius: 10px;
}
.onboarding-step .download-screen-bottom .score-div .progressbar-col {
  padding-right: 10px;
}
.onboarding-step
  .download-screen-bottom
  .score-div
  .progressbar-col
  .indicator {
  text-align: center;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
}
.onboarding-step
  .download-screen-bottom
  .score-div
  .progressbar-col
  .indicator
  .score {
  margin: 0;
  font-weight: 700;
  font-size: 20px;
  color: #222831;
}
.onboarding-step
  .download-screen-bottom
  .score-div
  .progressbar-col
  .indicator
  .tag {
  margin: 4px auto 0 auto;
  padding: 2px 8px;
  font-size: 8px;
  font-weight: 500;
  color: #ffffff;
  text-transform: capitalize;
  background-color: #eeeeee;
  width: fit-content;
  border-radius: 30px;
}
.onboarding-step .download-screen-bottom .score-div .text-col p.para {
  margin: 0 0 20px 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
  color: #222831;
}
.onboarding-step .download-screen-bottom .score-div .text-col p.link {
  margin: 0;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #ff4a01;
}

.download-screen-bottom {
  background: radial-gradient(
    99.17% 99.17% at 50% 50%,
    #434343 0%,
    #121212 100%
  );
}
.onboarding-step .download-screen-bottom .score-div .text-col p.link svg {
  margin: 2px 0 0 4px;
}
.onboarding-step .download-screen-bottom .download-div {
  padding: 16px 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(96.62deg, #ff7f4c -0.54%, #ff4a01 82.88%);
  border-radius: 12px;
}
.onboarding-step .download-screen-bottom .download-div .text-col p.para {
  margin: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}
.onboarding-step .download-screen-bottom .download-div p.link {
  margin: 20px 0 0 0;
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  color: #222831;
  display: flex;
  align-items: center;
}
.onboarding-step .download-screen-bottom .download-div p.link svg {
  margin: 2px 0 0 4px;
}

.options-wrapper {
  display: flex;
  flex-wrap: wrap;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}
.client-numbers {
  justify-content: center;
}
.options-chip {
  cursor: pointer;
  margin: 12px;
  padding: 12px;
  border-radius: 8px;
}

.client-train {
  width: 70%;
}

.accountDeletion-wrapper {
  background-color: #eeeeee;
  margin: 11% auto;
  max-width: 540px;
  overflow: hidden;
  padding: 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.accountDeletion-wrapper h1 {
  margin: 0px;
  padding: 0px;
  text-align: center;
  position: relative;
}
.heading-AD {
  display: flex;
  align-items: center;
}
.heading-AD h1 {
  margin: auto;
}

.account-deletion-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.action-button-bottom {
  display: block;
  margin: 0 auto;
  background-color: #222831;
  color: #ffffff;
  padding: 20px 75px;
  font-size: 16px;
  font-weight: 700;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  width: 100%;
}
.action-button-confirm {
  display: block;
  margin: 50px auto 10px;
  background-color: #ffffff;
  color: #ff0000;
  padding: 20px 75px;
  font-size: 16px;
  font-weight: 700;
  border-radius: 10px;
  border: 1px solid #bdbfc1;
  cursor: pointer;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .accountDeletion-wrapper {
    min-height: 100vh;
    margin: 0 auto;
    padding: 100px 18px 18px 18px;
  }
  .action-button-bottom {
    margin: 0;
  }
}
.accountDeletion-wrapper .css-w0isml-MuiInputBase-root-MuiOutlinedInput-root,
.css-187n7ak-MuiInputBase-root-MuiOutlinedInput-root {
  border: none !important;
  border-radius: 10px !important;
  background-color: #ffffff !important;
}

fieldset.MuiOutlinedInput-notchedOutline {
  border: 1px solid transparent !important;
  border-bottom: 2px solid rgba(16, 19, 23, 1) !important;
}

.about-page {
  background-image: url("../v2_assets/about_bg.webp");
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  font-family: "Satoshi";
  font-weight: 900;
  display: flex;
  padding: 12% 3% 0 66px;
  align-items: flex-start;
  justify-content: space-between;
}

.about-right-parent,
.about-right {
  height: 100%;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  overflow: hidden;
}

.heading-small {
  font-family: Satoshi;
  font-size: 40px;
  font-weight: 600;
  line-height: 54px;
  letter-spacing: -0.04em;
  text-align: left;
  margin: 0px;
}

.heading-large {
  font-family: Satoshi;
  font-size: 70px;
  font-weight: 700 !important;
  line-height: 95.98px;
  letter-spacing: -0.04em;
  text-align: left;
  margin: 0px;
}

.about-right p {
  margin-bottom: 5px;
}

.about-right {
  overflow-y: auto !important;
  padding-bottom: 500px;
}

.about-title {
  font-family: Satoshi;
  font-size: 120px;
  font-weight: 900;
  line-height: 143.97px;
  letter-spacing: -0.04em;
  text-align: left;
  color: rgba(0, 0, 0, 0.06);
  margin-bottom: 0px;
}

.about-detail-1,
.about-detail-2 {
  font-family: Satoshi;
  font-size: 25.25px;
  font-weight: 500;
  line-height: 36.78px;
  letter-spacing: -0.04em;
  text-align: start;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  margin-top: 150px;
  transition: 1s ease;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.about-right::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.about-right {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/*  */

.MuiList-root.MuiList-padding.MuiMenu-list a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.8);
}

.MuiList-root.MuiList-padding.MuiMenu-list a.active {
  color: rgba(255, 74, 1, 1);
}

.MuiList-root.MuiList-padding.MuiMenu-list a:nth-child(1) {
  margin-bottom: 10px;
}

.link-wrapper .link {
  text-decoration: none;
  font-family: Satoshi;
  font-size: 15px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: -0.04em;
  text-align: left;
  color: rgba(0, 0, 0, 0.8);
}

.link-wrapper .link.active {
  color: rgba(255, 74, 1, 1) !important;
  border-bottom: 2px solid #ff4a01;
}
